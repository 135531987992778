<template>
  <div>
    <a-modal
      class="case-case-detail-modal"
      v-model:visible="isShowCaseDetail"
      :title="caseInfoModalTitle"
      :closable="false"
      width="750px"
    >
      <template #footer>
        <div v-if="caseDetailMode == 'INFO'">
          <a-button
            class="btn-confirm-green"
            key="modify"
            @click="clickStartModifyMode"
          >
            {{ $t('LM001.edit') }}
          </a-button>
        </div>
        <div v-if="caseDetailMode == 'MODIFY'">
          <a-button
            class="btn-cancel-gray"
            key="cancel"
            @click="clickCancelModify"
          >
            {{ $t('LM001.cancel') }}
          </a-button>
          <a-button
            class="btn-confirm-green"
            key="submit"
            @click="clickSaveModify"
            :loading="isSaveloading"
          >
            {{ $t('LM001.save') }}
          </a-button>
        </div>
        <div v-if="caseDetailMode == 'CREATE'">
          <a-button
            class="btn-cancel-gray"
            key="cancel"
            @click="clickCancelModify"
          >
            {{ $t('LM001.cancel') }}
          </a-button>
          <a-button
            class="btn-confirm-green"
            key="submit"
            @click="clickSaveCreate"
            :loading="isSaveloading"
          >
            {{ $t('LM001.save') }}
          </a-button>
        </div>
      </template>
      <a-form
        ref="caseFormRef"
        :model="caseDetail"
        :label-col="caseDetailLabelCol"
        :wrapper-col="caseDetailWrapperCol"
        :rules="caseDetailRules"
      >
        <!-- Personal Info-->
        <a-card class="second-card-in-form">
          <div class="second-card-label-in-form">
            {{ $t('LM001.personalInfoArea') }}
          </div>
          <a-form-item
            :label="$t('LM001.name')"
            name="name"
            v-if="isWebConfigShowName"
          >
            <a-input
              v-model:value="caseDetail.name"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
            />
          </a-form-item>
          <a-form-item :label="$t('LM001.gender')" name="gender">
            <a-select
              :allowClear="true"
              v-model:value="caseDetail.gender"
              :placeholder="$t('LM001.pleaseChooseGender')"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
            >
              <a-select-option key="M" value="M">
                {{ $t('LM001.male') }}
              </a-select-option>
              <a-select-option key="F" value="F">
                {{ $t('LM001.female') }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="$t('LM001.birthday')" name="birthday">
            <a-date-picker
              v-model:value="caseDetail.birthday"
              :format="birthdayDateFormat"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
            />
          </a-form-item>
          <a-form-item :label="$t('LM001.height')" name="height">
            <a-input
              v-model:value="caseDetail.height"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :suffix="getHeightUnit()"
              :placeholder="getHeightPlaceholder()"
            />
          </a-form-item>
        </a-card>
        <!-- ID Info-->
        <a-card class="second-card-in-form">
          <div class="second-card-label-in-form">
            {{ $t('LM001.idInfoArea') }}
          </div>
          <div v-if="!isWebConfigShowEPass" class="first-card-warning">
            {{
              isShowCustomIdCardIdRocIdWarning
                ? $t('LM001.pleaseInputCustomIdCardIdRocId')
                : ' '
            }}
          </div>
          <a-form-item
            :label="$t('LM001.idCode')"
            name="customId"
            v-if="isWebConfigEnableCustomId"
          >
            <a-input
              v-model:value="caseDetail.customId"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :placeholder="$t('LM001.idHint')"
              style="width: 90%; margin-right: 8px"
            />
            <MinusCircleOutlined
              class="dynamic-delete-button"
              @click="removeInputIdCode()"
            />
          </a-form-item>
          <a-form-item
            :label="$t('LM001.rocId')"
            name="rocId"
            v-if="isWebConfigShowRocId"
          >
            <a-input
              v-model:value="caseDetail.rocId"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :placeholder="$t('LM001.rocIdHint')"
              style="width: 90%; margin-right: 8px"
            />
            <MinusCircleOutlined
              class="dynamic-delete-button"
              @click="removeInputRocId()"
            />
          </a-form-item>
          <a-form-item
            :label="$t('LM001.cardNum')"
            name="cardId"
            v-if="isWebConfigShowCardId"
          >
            <a-input
              v-model:value="caseDetail.cardId"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :placeholder="$t('LM001.cardNumHint')"
              style="width: 90%; margin-right: 8px"
            />
            <MinusCircleOutlined
              class="dynamic-delete-button"
              @click="removeInputCardNum()"
            />
          </a-form-item>
          <a-form-item
            :label="$t('LM001.mobilePhone')"
            name="mobilePhoneId"
            v-if="isWebConfigShowMobilePhoneId"
            :rules="[
              {
                required: isWebConfigShowEPass,
                validator: validateIdInfo,
                trigger: 'blur',
              },
            ]"
          >
            <a-input
              v-model:value="caseDetail.mobilePhoneId"
              :disabled="
                !isWebConfigEnableMobilePhoneId ||
                !isEnablePhoneNumberEdit ||
                (caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus)
              "
              :placeholder="
                isWebConfigShowMobilePhoneId && isWebConfigShowEPass
                  ? $t('LM001.ePassPhoneNumberHint')
                  : $t('LM001.mobilePhone')
              "
              style="width: 90%; margin-right: 8px"
            />
            <MinusCircleOutlined
              class="dynamic-delete-button"
              @click="removeInputMobilePhoneId()"
            />
          </a-form-item>
          <a-form-item
            :label="$t('LM001.myKadId')"
            name="myKadId"
            v-if="isWebConfigShowMyKadId"
          >
            <a-input
              v-model:value="caseDetail.myKadId"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :placeholder="$t('LM001.myKadId')"
              style="width: 90%; margin-right: 8px"
            />
            <MinusCircleOutlined
              class="dynamic-delete-button"
              @click="removeInputMyKadId()"
            />
          </a-form-item>
        </a-card>
        <!-- status management-->
        <a-card class="second-card-in-form">
          <div class="second-card-label-in-form">
            {{ $t('LM001.statusManagementArea') }}
          </div>
          <a-form-item
            :label="$t('LM001.eligibilityStatus')"
            name="eligibilityStatus"
            v-if="isShowEligibilityStatus"
          >
            <a-switch v-model:checked="caseDetail.status" />
          </a-form-item>
          <a-form-item
            :label="$t('LM001.validityPeriod')"
            name="validityPeriod"
          >
            <a-date-picker
              v-model:value="caseDetail.validityPeriod"
              :format="birthdayDateFormat"
              :placeholder="$t('LM001.validityPeriodNone')"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :disabled-date="disableValiditydDate"
            />
          </a-form-item>
          <a-form-item
            :label="$t('LM001.ePassStatus')"
            name="ePassStatus"
            v-if="isShowEPassStatus && isWebConfigShowEPass"
          >
            {{ getEPassLocalTime() }}
          </a-form-item>
          <a-form-item :label="$t('LM001.group')" v-if="isWebConfigShowGroup">
            <a-select
              :allowClear="true"
              v-model:value="caseDetail.group"
              :placeholder="$t('LM001.pleaseChooseGroup')"
              :disabled="
                caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
              "
              :options="caseDetailGroupOptions"
            >
              <a-select-option
                v-for="item in caseDetailGroupOptions"
                :key="item.value"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-card>
        <!-- other-->
        <a-card class="second-card-in-form">
          <div class="second-card-label-in-form">
            {{ $t('LM001.othersArea') }}
          </div>
          <a-form-item
            :label="$t('LM001.phoneNum')"
            name="phoneNumber"
            v-if="isWebConfigShowPhoneNumber"
          >
            <a-input
              v-model:value="caseDetail.phoneNumber"
              :disabled="
                caseDetailMode == 'INFO' || !isWebConfigEnablePhoneNumber
              "
            />
          </a-form-item>
          <a-form-item :label="$t('LM001.remark')" name="remark">
            <a-textarea
              v-model:value="caseDetail.remark"
              :disabled="caseDetailMode == 'INFO'"
              :rows="4"
              :maxlength="255"
            ></a-textarea>
            <!-- <a-input
              v-model:value="caseDetail.remark"
              :disabled="caseDetailMode == 'INFO'"
            /> -->
          </a-form-item>
        </a-card>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, computed, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import dayjs from 'dayjs'
  import { createVNode } from 'vue'
  import { Modal } from 'ant-design-vue'
  import {
    MinusCircleOutlined,
    ExclamationCircleOutlined,
  } from '@ant-design/icons-vue'

  const props = defineProps({
    visible: Boolean,
    mode: String, // 'INFO', 'MODIFY', 'CREATE'
    detail: Object,
    groupOptions: Array,
    saveLoading: Boolean,
    phoneedit: Boolean,
  })

  const emits = defineEmits([
    'update:visible',
    'start-modify',
    'cancel-modify',
    'save-modify',
    'save-create',
  ])

  const store = useStore()
  const { t } = useI18n()

  const createCase = (postBody) => store.dispatch('case/createCase', postBody)
  const modifyCaseInfo = (postBody) =>
    store.dispatch('case/modifyCaseInfo', postBody)

  const webConfig = computed(() => store.state.user.webConfig)
  const measureThreshold = computed(
    () => store.state.measureThreshold.measureThreshold
  )

  const caseDetailLabelCol = ref({ span: 8 })
  const caseDetailWrapperCol = ref({ span: 16 })
  const caseAddUserWrapperCol = ref({ span: 18, offset: 6 })

  const isShowCaseDetail = ref(props.visible)
  const caseDetailMode = ref(props.mode)
  const caseDetail = ref(props.detail)
  const caseDetailGroupOptions = ref(props.groupOptions)
  const isSaveloading = ref(props.saveLoading)
  const caseDetailEligibilityStatus = ref(true)
  const birthdayDateFormat = ref('YYYY-MM-DD')
  const isEnablePhoneNumberEdit = ref(props.phoneedit)
  const isShowCustomIdCardIdRocIdWarning = ref(false)
  const heightUnit = ref('cm') // 預設公制

  const caseFormRef = ref(undefined)

  const caseDetailRules = ref({
    cardId: [{ validator: validateIdInfo, trigger: 'blur' }],
    customId: [{ validator: validateIdInfo, trigger: 'blur' }],
    rocId: [{ validator: validateIdInfo, trigger: 'blur' }],
    myKadId: [{ validator: validateIdInfo, trigger: 'blur' }],
    gender: [{ required: true, message: '' }],
    height: [
      {
        required: true,
        validator: validateHeight,
        trigger: 'blur',
      },
    ],
    birthday: [{ required: true, message: '' }],
  })

  watch(
    () => props.visible,
    (newVal) => {
      console.log('watch visible ', newVal)
      if (caseDetailMode.value === 'CREATE') {
        isShowCustomIdCardIdRocIdWarning.value = true
      } else if (caseDetailMode.value === 'MODIFY') {
        isShowCustomIdCardIdRocIdWarning.value = false
      }
      if (caseFormRef.value) {
        caseFormRef.value.clearValidate()
      }
      isShowCaseDetail.value = newVal
    }
  )

  watch(
    () => props.phoneedit,
    (newVal) => {
      console.log('watch phoneedit ', newVal)
      isEnablePhoneNumberEdit.value = newVal
    }
  )

  watch(
    () => props.detail,
    (newVal) => {
      console.log('watch detail ', newVal)
      caseDetail.value = newVal
    }
  )

  watch(
    () => props.mode,
    (newVal) => {
      console.log('watch mode ', newVal)
      caseDetailMode.value = newVal
    }
  )

  const clickStartModifyMode = () => {
    emits('start-modify')
  }

  const clickCancelModify = () => {
    emits('cancel-modify')
  }

  const clickSaveModify = async () => {
    try {
      await caseFormRef.value.validate()
      emits('save-modify')
    } catch (e) {
      console.log('error', e)
    }
  }

  const clickSaveCreate = async () => {
    try {
      await caseFormRef.value.validate()
      emits('save-create')
    } catch (e) {
      console.log('error', e)
    }
  }

  const validateIdInfo = () => {
    if (
      !caseDetail.value.customId &&
      !caseDetail.value.cardId &&
      !caseDetail.value.rocId &&
      !caseDetail.value.mobilePhoneId &&
      !caseDetail.value.myKadId
    ) {
      isShowCustomIdCardIdRocIdWarning.value = true
      return Promise.reject('')
      // return Promise.reject(t('LM001.pleaseInputCustomIdCardIdRocId'))
    } else if (isWebConfigShowEPass.value && !caseDetail.value.mobilePhoneId) {
      isShowCustomIdCardIdRocIdWarning.value = true
      return Promise.reject('')
    } else {
      isShowCustomIdCardIdRocIdWarning.value = false
      return Promise.resolve()
    }
  }
  const validateHeight = () => {
    const re = /^\d'\s?(\d{1,2}")?$/
    if (
      measureThreshold.value?.stature?.unit === 'ft&in' &&
      re.test(caseDetail.value.height) === false
    ) {
      return Promise.reject('')
    } else if (
      measureThreshold.value?.stature?.unit !== 'ft&in' &&
      isNaN(Number(caseDetail.value.height))
    ) {
      return Promise.reject('')
    } else {
      return Promise.resolve()
    }
  }

  const getHeightUnit = () => {
    if (measureThreshold.value?.stature?.unit === 'ft&in') {
      // ft&in
      heightUnit.value = 'ft&in'
      return t('MS005.ft')
    } else {
      // 預設公制
      heightUnit.value = 'cm'
      return t('MS005.cm')
    }
  }
  const getHeightPlaceholder = () => {
    if (measureThreshold.value?.stature?.unit === 'ft&in') {
      return '5\'7"'
    } else {
      // 預設公制
      return '170'
    }
  }
  const getEPassLocalTime = () => {
    if (caseDetail.value.ePassIssued) {
      const str = t('LM001.ePassStatusTrue')
      const strDate = dayjs(caseDetail.value.ePassIssued).format('YYYY-MM-DD')
      return `${str}(${strDate})`
    } else {
      return t('LM001.ePassStatusFalse')
    }
  }

  const disableValiditydDate = (current) => {
    const maxDate = dayjs(expiredTs.value).endOf('day')
    const today = dayjs().startOf('day')
    return current && (current <= today || current > maxDate)
  }

  const removeInputIdCode = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.customId = ''
  }
  const removeInputRocId = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.rocId = ''
  }

  const removeInputCardNum = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.cardId = ''
  }

  const removeInputMobilePhoneId = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    if (isEnablePhoneNumberEdit.value === false) {
      Modal.confirm({
        title: t('LM001.deleteEpassPhoneNumberTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('LM001.deleteEpassPhoneNumberWarning'),
        okText: t('LM005.confirm'),
        okType: 'danger',
        cancelText: t('LM005.cancel'),
        onOk() {
          isEnablePhoneNumberEdit.value = true
          caseDetail.value.mobilePhoneId = ''
        },
        onCancel() {},
      })
    } else {
      caseDetail.value.mobilePhoneId = ''
    }
  }

  const removeInputMyKadId = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.myKadId = ''
  }

  const caseInfoModalTitle = computed(() => {
    if (caseDetailMode.value === 'INFO') {
      return 'Case Info'
    } else if (caseDetailMode.value === 'MODIFY') {
      return 'Edit Info'
    } else if (caseDetailMode.value === 'CREATE') {
      return 'Create Case'
    } else {
      return 'Case Info'
    }
  })

  const isShowLinkToMeasurementsPeriod = computed(() => {
    let retData = true
    webConfig.value.forEach((conf) => {
      if (conf.id === 'health_data' && conf.privilege.show === false) {
        retData = false
      }
    })
    return retData
  })
  const isWebConfigEnableName = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'name') {
              return caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigEnableCustomId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'CUSTOM_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableCardId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'NFC_CARD_NUMBER') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableRocId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'ROC_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableMobilePhoneId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'MOBILE_PHONE') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableMyKadId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (
            webConfig.value[i].idTypes[j].key === 'MY_KAD_ID' ||
            webConfig.value[i].idTypes[j].key === 'My_Kad_ID'
          ) {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnablePhoneNumber = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'mobile') {
              return caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigEnableGroup = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'group') {
              return caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowName = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'name') {
              return caseColumn[j].privilege.show
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowCustomId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'CUSTOM_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowCardId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'NFC_CARD_NUMBER') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowRocId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'ROC_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowMobilePhoneId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'MOBILE_PHONE') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowMyKadId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (
            webConfig.value[i].idTypes[j].key === 'MY_KAD_ID' ||
            webConfig.value[i].idTypes[j].key === 'My_Kad_ID'
          ) {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowPhoneNumber = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'mobile') {
              return caseColumn[j].privilege.show
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowGroup = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'group') {
              return caseColumn[j].privilege.show
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowEPass = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'vas_epass' &&
        webConfig.value[i].privilege.show === true
      ) {
        console.log('isWebConfigShowEPass', webConfig.value[i].privilege.show)
        return true
      }
    }
    console.log('isWebConfigShowEPass', false)
    return false
  })
  const isEnableCreateCase = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'create_subject') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isEnableGroupManagement = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'group_mgnt') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isEnableCaseEdit = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'subject_edit') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isEnableCaseDelete = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'subject_delete') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isShowEligibilityStatus = computed(() => {
    if (caseDetailMode.value === 'MODIFY') {
      return true
    }
    return false
  })
  const isShowEPassStatus = computed(() => {
    if (caseDetailMode.value === 'MODIFY') {
      return true
    }
    return false
  })
</script>

<style scoped>
  .first-card-in-form {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .second-card-in-form {
    margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .first-card-warning {
    height: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ea6363;
  }
  .card-label-in-form {
    width: max-content;
    margin-left: -20px;
    padding-left: 15px;
    padding-right: 15px;
    background: #4dbaa7;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    color: #ffffff;
  }
  .btn-confirm-green {
    width: 135px;
    background: #4ebaa8;
    border-radius: 4px;
  }
  .btn-cancel-gray {
    width: 135px;
    background: #bdbdbd;
    border-radius: 4px;
  }
  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }
</style>
